<template>
  <b-modal
    id="product-show-modal"
    ref="product-show-modal"
    scrollable
    hide-footer
    size="xl"
    :title="
      productData
        ? `${productData.nameGeo} / ${productData.nameEng}`
        : $i18n.t('global.details')
    "
    @hide="hideModal"
  >
    <b-modal id="main-image-modal" size="lg" ok-only>
      <img
        v-if="tempImageUrl"
        :src="tempImageUrl"
        alt="main image"
        class="modal-image mx-auto"
      />
    </b-modal>
    <div v-if="productData">
      <b-col>
        <div v-if="productData.mainImage">
          <label for="">{{ $i18n.t('productPopup.mainImage') }}</label>
          <div>
            <img
              :src="productData.mainImage.url"
              class="popup-gallery-img cursor-pointer"
              @click="handleMainImageModal(productData.mainImage.url)"
            />
          </div>
        </div>
        <div v-if="productData.gallery && productData.gallery.length">
          <label for="">{{ $i18n.t('productPopup.galleryImages') }}</label>
          <div class="d-flex flex-wrap">
            <div v-for="img in productData.gallery" :key="img.id">
              <img
                :src="img.url"
                class="popup-gallery-img cursor-pointer"
                @click="handleMainImageModal(img.url)"
              />
            </div>
          </div>
        </div>

        <h2 class="d-flex justify-content-center mt-1">{{ $i18n.t('productPopup.about') }}:</h2>
      </b-col>
      <b-card>
        <h6 class="mb-2">{{ $i18n.t('productPopup.basicInfo') }}</h6>
        <b-row>
          <b-col md="3">
            <label>{{ $i18n.t('productPopup.nameGeo') }}:</label>
            <p>{{ productData.nameGeo }}</p>
          </b-col>
          <b-col md="3">
            <label>{{ $i18n.t('productPopup.nameEng') }}:</label>
            <p>{{ productData.nameEng }}</p>
          </b-col>
          <b-col v-if="productData.upcOrSku" md="3">
            <label>SKU:</label>
            <p>{{ productData.upcOrSku }}</p>
          </b-col>
          <b-col sm="3">
            <label>{{ $i18n.t('productPopup.serviceType') }}:</label>
            <p>{{ serviceTypeList[productData.serviceType] }}</p>
          </b-col>
          <b-col sm="3">
            <label>{{ $i18n.t('productPopup.giftType') }}:</label>
            <p>
              {{ giftTypeList[productData.productGenericCategory.giftType] }}
            </p>
          </b-col>
          <b-col sm="3">
            <label>{{ $i18n.t('productPopup.category') }}:</label>
            <p>
              {{
                `${productData.productCategory.productCategoryNameGeo} /
									${productData.productCategory.productCategoryNameEng}`
              }}
            </p>
          </b-col>
          <b-col sm="3">
            <label>{{ $i18n.t('productPopup.subCategory') }}:</label>
            <p>
              {{
                `${productData.productSubCategory.productSubCategoryNameGeo} /
									${productData.productSubCategory.productSubCategoryNameEng}`
              }}
            </p>
          </b-col>
          <b-col sm="3">
            <label>{{ $i18n.t('productPopup.genericCategory') }}:</label>
            <p>
              {{
                `${productData.productGenericCategory.productGenericCategoryNameGeo} /
									${productData.productGenericCategory.productGenericCategoryNameEng}`
              }}
            </p>
          </b-col>
          <b-col v-if="productData.branch" sm="3">
            <b-form-group :label="$i18n.t('productPopup.branches')" label-for="branches">
              <p>
                {{
                  `${productData.branch.nameEng} / ${productData.branch.nameGeo}`
                }}
              </p>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <h6>{{ $i18n.t('productPopup.characteristics') }}</h6>
            <b-row>
              <b-col sm="3">
                <label>{{ $i18n.t('productPopup.count') }}:</label>
                <p>{{ productData.count }}</p>
              </b-col>
              <b-col sm="3">
                <label>{{ $i18n.t('productPopup.price') }}:</label>
                <p>{{ productData.price }}</p>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12">
            <h6>{{ $i18n.t('productPopup.characteristics') }}:</h6>
            <b-row>
              <b-col sm="3">
                <label>{{ $i18n.t('productPopup.minAge') }}:</label>
                <p>{{ productData.minAge }}</p>
              </b-col>
              <b-col sm="3">
                <label>{{ $i18n.t('productPopup.maxAge') }}:</label>
                <p>{{ productData.maxAge }}</p>
              </b-col>
              <b-col sm="3">
                <label>{{ $i18n.t('productPopup.targetGender') }}:</label>
                <p>{{ genderLabel[productData.productTargetGender] }}</p>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="3">
            <b-form-group :label="$i18n.t('productPopup.commissionType')" label-for="i-name-eng">
              <b-form-select
                v-model="productData.commissionFeeType"
                :options="commissionTypes"
              />
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group :label="$i18n.t('productPopup.emotyCommissionFee')" label-for="i-name-eng">
              <b-form-input
                id="i-eng"
                v-model="productData.emotyPercentCommissionValue"
                type="number"
                placeholder="Fee here..."
                :disabled="productData.commissionFeeType === 2"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if="productData.serviceType !== 4" class="mb-2">
        <h6 class="mb-2">{{ $i18n.t('productPopup.location') }}</h6>
        <b-row>
          <b-col md="4">
            <label>{{ $i18n.t('productPopup.location') }}:</label>
            <p>
              {{ `${productData.locationGeo} /  ${productData.locationEng}` }}
            </p>
          </b-col>
          <b-col sm="3">
            <label>{{ $i18n.t('productPopup.city') }}:</label>
            <p>
              {{
                `${productData.city.cityNameGeo} / ${productData.city.cityNameEng}`
              }}
            </p>
          </b-col>
          <b-col sm="3">
            <label>Location free:</label>
            <p>
              {{ productData.isLocationFree ? "Yes" : "No" }}
            </p>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <h6 class="mb-2">{{ $i18n.t('productPopup.description') }}</h6>
        <b-row>
          <b-col md="6">
            <label>{{ $i18n.t('productPopup.descriptionGeo') }}:</label>
            <div v-html="productData.descriptionGeo && productData.descriptionGeo.split('\r').join('<br />')"/>
          </b-col>
          <b-col md="6">
            <label>{{ $i18n.t('productPopup.descriptionEng') }}:</label>
            <div v-html="productData.descriptionEng && productData.descriptionEng.split('\r').join('<br />')"/>
          </b-col>
          <b-col md="6">
            <label>{{ $i18n.t('productPopup.termsOfUseGeo') }}:</label>
            <p>{{ productData.useTermsGeo }}</p>
          </b-col>
          <b-col md="6">
            <label>{{ $i18n.t('productPopup.termsOfUseEng') }}:</label>
            <p>{{ productData.useTermsEng }}</p>
          </b-col>
          <b-col md="3">
            <label>{{ $i18n.t('productPopup.activeFrom') }}:</label>
            <p>
              {{
                productData.activeFrom
                  ? productData.activeFrom.slice(0, 16)
                  : ""
              }}
            </p>
          </b-col>
          <b-col v-if="productData.activeTo" md="3">
            <label>{{ $i18n.t('productPopup.activeTo') }}:</label>
            <p>
              {{
                productData.activeTo ? productData.activeTo.slice(0, 16) : ""
              }}
            </p>
          </b-col>
          <b-col v-if="productData.sale" md="3">
            <label>{{ $i18n.t('productPopup.sale') }}:</label>
            <p v-if="productData.sale.type === 1">
              {{ productData.sale.fixed }} Fixed
            </p>
            <p v-else>{{ productData.sale.percent }} %</p>
          </b-col>
          <b-col v-if="productData.saleUntilAt" md="3">
            <label>Sale unit at:</label>
            <p>
              {{
                productData.saleUntilAt
                  ? productData.saleUntilAt.slice(0, 16)
                  : ""
              }}
            </p>
          </b-col>
        </b-row>
      </b-card>
      <app-collapse>
        <app-collapse-item
          v-if="productData.productAttributes.length"
          title="Attributes"
          class="mb-2"
        >
          <div
            v-for="attribute in productData.productAttributes"
            :key="attribute.id"
            class="p-1 border my-1"
          >
            <p class="mb-0">
              {{ attribute.nameGeo }} / {{ attribute.nameEng }}
            </p>
            <small class="mb-0">
              {{ attribute.value.valueEng }} /
              {{ attribute.value.valueGeo }}
            </small>
          </div>
        </app-collapse-item>
        <app-collapse-item
          v-if="productData.attributes"
          title="Attributes"
          class="mb-2"
        >
          <div
            v-for="attribute in productData.attributes"
            :key="attribute.id"
            class="p-1 border my-1"
          >
            <p class="mb-0">
              {{ attribute.nameGeo }} / {{ attribute.nameEng }}
            </p>
            <small class="mb-0">
              {{ attribute.value.valueEng }} /
              {{ attribute.value.valueGeo }}
            </small>
          </div>
        </app-collapse-item>
        <app-collapse-item
          v-if="productData.productConfigurations.length"
          :title="$i18n.t('productPopup.configurations')"
          class="mb-2"
        >
          <app-collapse
            v-for="item in productData.productConfigurations"
            :key="item.productSubCategoryId"
            type="margin"
            accordion
          >
            <app-collapse-item :title="item.colorCode">
              <div>
                <img v-if="item.mainImage"
                  :src="item.mainImage.url"
                  class="popup-gallery-img cursor-pointer"
                  @click="handleMainImageModal(item.mainImage.url)"
                />
              </div>
              <div v-if="item.gallery.length">
                <label for="">{{ $i18n.t('productPopup.galleryImages') }}</label>
                <div class="d-flex flex-wrap">
                  <div v-for="img in item.gallery" :key="img.id">
                    <img
                      :src="img.url"
                      class="popup-gallery-img cursor-pointer"
                      @click="handleMainImageModal(img.url)"
                    />
                  </div>
                </div>
              </div>
              <b-form-group label-for="i-name-eng">
                <div class="mb-1">
                  <label>{{ $i18n.t('productPopup.descriptionGeo') }}:</label>
                  <div v-html="productData.descriptionGeo && productData.descriptionGeo.split('\r').join('<br />')"/>
                </div>
                <div v-for="option in item.options" :key="option.id">
                  <div>
                    <label>{{ $i18n.t('productPopup.option') }}:</label>
                    <p>
                      {{ `${option.sizeOptionGeo} / ${option.sizeOptionEng}` }}
                    </p>
                  </div>
                  <div class="d-flex mb-1">
                    <div class="mr-2 border rounded px-2">
                      <label>UPC/SKU:</label>
                      <p>{{ option.upcOrSku }}</p>
                    </div>
                    <div class="mr-2 border rounded px-2">
                      <label>{{ $i18n.t('productPopup.quantity') }}:</label>
                      <p>{{ option.count }}</p>
                    </div>
                  </div>
                  <hr />
                </div>
              </b-form-group>
            </app-collapse-item>
          </app-collapse>
        </app-collapse-item>
      </app-collapse>
    </div>
  </b-modal>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";

import {
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import isAllowed from "@core/utils/isAllowed";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapState } from "vuex";

export default {
  components: {
    BCol,
    BCard,
    BFormSelect,
    BRow,
    AppCollapse,
    BFormInput,
    BFormGroup,
    AppCollapseItem,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    getProducts: {
      type: Function,
      default: () => true,
    },
    isApproved: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productData: null,
      tempImageUrl: null,
      algorithmDescriptorsData: null,
    };
  },
  computed: {
    ...mapState("products", [
      "products",
      "algorithmDescriptors",
      "isTableBusy",
    ]),
    areActionsAllowed() {
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      return isAllowed(["Admin", "Editor", "Category Manager"], roles);
    },
    serviceTypeList() {
      return {
        1: "Experiential",
        2: "Material",
        3: "Digital",
        4: "Card",
      };
    },
    genderLabel() {
      return {
        1: "Male",
        2: "Female",
        3: "Both",
      };
    },
    giftTypeList() {
      return {
        1: "Both",
        2: "Daily",
        3: "Holiday",
      };
    },
    commissionTypes() {
      return [
        {
          value: 0,
          text: "Both",
        },
        {
          value: 1,
          text: "Emoty",
        },
        {
          value: 2,
          text: "Markup",
        },
      ];
    },
  },
  watch: {
    data(newVal) {
      this.productData = newVal;
    },
    algorithmDescriptors(newVal) {
      this.algorithmDescriptorsData = newVal;
    },
  },
  mounted() {
    this.productData = this.data
  },
  methods: {
    toast(message = "success", type = "success") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant: type,
        },
      });
    },
    handleMainImageModal(imageUrl) {
      this.tempImageUrl = imageUrl;
      this.$bvModal.show("main-image-modal");
    },
    hideModal() {
      this.$refs["product-show-modal"].hide();
    },
  },
};
</script>

<style lang="scss">
.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  button {
    width: 47%;
  }
}

.feather-24 {
  width: 16px;
  height: 16px;
}

.image-wrapper {
  margin: 0 auto;
  width: auto !important;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 100%;
  }
}

.select-wrapper {
  width: 100%;
}

.inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.descriptorsWrapper {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  // justify-content: space-between;
}

.popup-gallery-img {
  max-height: 125px;
  margin: 0.5rem;
  border-radius: 15px;
}
.modal-body {
  display: flex;
}
.modal-image {
  margin: 0 auto;
  max-width: 100%;
  object-fit: cover;
  max-height: 500px;
}
</style>
