<template>
  <b-card
    :header="this.$i18n.t('products.products')"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <ProductPopupVue :data="modalProduct" />
    <ModalProductMobileView :product="mobileViewProduct" />
    <!-- start of filters -->
    <FiltersAndSearch
      :id.sync="id"
      :searchKey.sync="searchKey"
      :activeOnly.sync="activeOnly"
      :primaryStatus.sync="status"
      :primaryStatusFields="productStatusList"
      :priceSort.sync="priceSort"
      :sortOrder.sync="sortOrder"
    />
    <!-- end of filters -->
    <!-- start of export -->
    <ExportFromTo :exportHandler="exportProductsHandler" />
    <!-- end export -->
    <b-table
      ref="refProductsListTable"
      sticky-header
      bordered
      hover
      responsive
      class="shadow-sm rounded products-table"
      thead-tr-class="order-main-thead"
      :items="productsList"
      :fields="fields"
      primary-key="id"
      show-empty
      :busy="showLoading"
      :empty-text="$t('global.no-items')"
      small
      @row-clicked="onRowClicked"
    >
      <template #cell(nameGeo)="data">
        <div class="d-flex align-items-center">
          <div class="d-flex flex-column">
            <span>{{ data.item.nameGeo }}</span>
            <span>{{ data.item.nameEng }}</span>
          </div>
          <div
            v-if="data.item.serviceType === 4"
            class="ml-auto border border-info rounded-pill d-flex align-items-center"
          >
            <span class="text-info" style="font-size: 11px; padding: 0 0.5rem">
              moodie
            </span>
          </div>
          <div
              v-if="data.item.status === 4"
              class="ml-auto border border-warning rounded-pill d-flex align-items-center"
          >
            <span class="text-warning" style="font-size: 11px; padding: 0 0.5rem">
              edited
            </span>
          </div>
        </div>
      </template>
      <template v-if="areActionsAllowed" #cell(actions)="data">
        <div class="d-flex">
          <b-button
            variant="flat-primary"
            class="btn-icon"
            @click="editProductButton(data.item)"
          >
            <feather-icon size="16" icon="EditIcon" />
          </b-button>
          <b-button
            variant="flat-primary"
            class="btn-icon"
            @click="dublicateProduct(data.item)"
          >
            <feather-icon size="16" icon="CopyIcon" />
          </b-button>
          <b-button
            class="btn-icon"
            variant="flat-danger"
            @click="removeProductButton(data.item)"
          >
            <feather-icon size="16" icon="TrashIcon" />
          </b-button>
          <b-button
            class="btn-icon"
            variant="flat-success"
            @click="getMobileView(data.item)"
          >
            <feather-icon size="16" icon="EyeIcon" />
          </b-button>
        </div>
      </template>
      <template #cell(hiddenUntilAt)="data">
        <hide-until-at
          :data="data"
          :hide-product-button="hideProductButton"
          :unhide-product-button="unhideProductButton"
        />
      </template>
    </b-table>
    <Pagination
      :fetch-data="fetchProducts"
      :total-rows="totalRows"
      :take.sync="take"
      :skip.sync="skip"
    />
  </b-card>
</template>

<script>
import { BTable, BCard, BButton } from "bootstrap-vue";
import { mapActions, mapState, mapMutations } from "vuex";
import ModalProductMobileView from "@/views/products/components/ModalProductMobileView.vue";
import isAllowed from "@core/utils/isAllowed";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import HideUntilAt from "./components/HideUntilAt.vue";
import ProductPopupVue from "./ProductPopup.vue";
import Pagination from "../components/Pagination/Pagination.vue";
import ExportFromTo from "../components/ExportFromTo/ExportFromTo.vue";
import FiltersAndSearch from "../components/FiltersAndSearch/FiltersAndSearch.vue";

const FileSaver = require("file-saver");

export default {
  components: {
    ModalProductMobileView,
    BTable,
    BCard,
    BButton,
    ProductPopupVue,
    Pagination,
    HideUntilAt,
    ExportFromTo,
    FiltersAndSearch,
  },
  data() {
    return {
      productsList: [],
      mobileViewProduct: null,
      modalProduct: null,
      take: 20,
      skip: 0,
      totalRows: 0,
      status: "",
      productStatusList: {
        1: this.$i18n.t("products.review-products"),
        4: this.$i18n.t("products.edited"),
      },
      sortOrder: 1,
      id: "",
      searchKey: "",
      activeOnly: true,
      priceSort: false,
    };
  },

  computed: {
    ...mapState("products", ["products", "product", "showLoading"]),
    areActionsAllowed() {
      // import isAllowed from "@core/utils/isAllowed";
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      return isAllowed(["Admin", "Content Manager"], roles);
    },
    fields() {
      return [
        {
          label: this.$i18n.t("products.actions"),
          key: "actions",
        },
        {
          label: "ID",
          key: "id",
        },
        {
          label: this.$i18n.t("products.name"),
          key: "nameGeo",
          tdClass: "nameColumn",
        },
        {
          key: "price",
          label: this.$i18n.t("products.price"),
          sortable: false,
          formatter: (value, key, item) => `${item.price.toFixed(2)} ₾`,
        },
        {
          key: "configurationCount",
          label: "Count",
          formatter: (value, key, item) => (item?.productConfigurations.length ? item?.configurationCount : item?.count),
        },
        {
          key: "emotyPercentCommissionValue",
          label: this.$i18n.t("products.comission"),
        },
        {
          label: this.$i18n.t("products.created_at"),
          key: "createdAt",
          sortable: false,
        },
        {
          label: this.$i18n.t("products.end_at"),
          key: "activeTo",
          sortable: false,
        },
        {
          label: this.$i18n.t("products.hiddenUntilAt"),
          key: "hiddenUntilAt",
        },
        {
          label: this.$i18n.t("products.category"),
          key: "productCategory",
          formatter: (value) =>
            `${value.productCategoryNameGeo} / ${value.productCategoryNameEng}`,
          sortable: false,
        },
        {
          label: this.$i18n.t("products.sub-category"),
          key: "productSubCategory",
          formatter: (value) =>
            `${value.productSubCategoryNameGeo} / ${value.productSubCategoryNameEng}`,
          sortable: false,
        },
        {
          label: this.$i18n.t("products.generic-category"),
          key: "productGenericCategory",
          formatter: (value) =>
            `${value.productGenericCategoryNameGeo} / ${value.productGenericCategoryNameEng}`,
          sortable: false,
        },
        {
          label: this.$i18n.t("products.sku"),
          key: "upcOrSku",
        },
      ];
    },
  },
  watch: {
    products(newVal) {
      this.productsList = newVal.products;
      this.totalRows = newVal.count;
    },
    searchKey() {
      this.fetchProducts();
    },
    activeOnly() {
      this.fetchProducts();
    },
    status() {
      this.fetchProducts();
    },
    id() {
      this.fetchProducts();
    },
    priceSort() {
      this.fetchProducts();
    },
    take() {
      this.fetchProducts();
    },
    sortOrder() {
      this.fetchProducts();
    },
  },
  created() {
    this.fetchProducts();
  },
  unMounted() {
    this.setProducts(null);
  },
  methods: {
    ...mapMutations("products", ["setProduct"]),
    ...mapActions("products", [
      "getProducts",
      "deleteApprovedProductById",
      "getApprovedProductById",
      "exportProducts",
      "hideProduct",
      "unhideProduct",
    ]),
    async fetchProducts() {
      try {
        await this.getProducts({
          productType: "products",
          skip: this.skip,
          take: this.take,
          searchKey: this.searchKey,
          sortOrder: this.sortOrder,
          status: this.status,
          id: this.id,
          activeOnly: this.activeOnly,
          priceSort: this.priceSort,
        });
        this.totalRows = this.products.count;
        this.productsList = this.products.products;
        console.log(this.products);
      } catch (error) {
        console.log(error);
      }
    },
    toast(message = "success", type = "success") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant: type,
        },
      });
    },
    async hideProductButton(item, hideDate) {
      try {
        await this.hideProduct({
          id: item.id,
          hiddenUntilAt: hideDate,
        });
        this.toast("Product hidden successfully", "success");
        this.getProducts({
          skip: 0,
          take: this.take,
          searchKey: "",
          productType: "products",
        });
      } catch (error) {
        this.toast("Something went wrong", "danger");
        console.log(error);
      }
    },
    async unhideProductButton(item) {
      try {
        await this.unhideProduct({
          id: item.id,
        });
        this.toast("Product unhidden successfully", "success");
        this.getProducts({
          skip: 0,
          take: this.take,
          searchKey: "",
          productType: "products",
        });
      } catch (error) {
        this.toast("Something went wrong", "danger");
        console.log(error);
      }
    },
    async exportProductsHandler(from, to) {
      const payload = { from, to };
      try {
        const response = await this.exportProducts(payload);
        const date = new Date().toLocaleDateString();
        FileSaver.saveAs(response.data, `Products-${date}.xlsx`);
        this.toast("Exported successfully", "success");
      } catch (error) {
        const responsObj = await error.response.data.text();
        this.toast(JSON.parse(responsObj).error.message, "danger");
      }
    },
    dublicateProduct(item) {
      if (item.serviceType !== 4) {
        this.$router.push(`/copy-product/${item.id}/approved`);
      } else {
        this.$router.push({
          name: "editSticker",
          params: { id: item.id, isApproved: true, isDuplicate: true },
        });
      }
    },
    editProductButton(prod) {
      if (prod.serviceType === 4) {
        this.$router.push(`/edit-sticker/${prod.id}/approved`);
      } else {
        this.$router.push(`/edit-product/${prod.id}/approved`);
      }
    },
    async removeProductButton(prod) {
      console.log("removeProductButton", prod)
      const result = await this.$bvModal.msgBoxConfirm(
        `${this.$t("global.delete_header")} - ${prod.nameGeo} / ${prod.nameEng}?`,
        {
          title: this.$t("global.delete_confirm"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("global.yes"),
          cancelTitle: this.$t("global.no"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (result) {
        this.deleteApprovedProductById(prod.id).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${prod.nameGeo} / ${prod.nameEng} ${this.$t("products.deleteSuccessMsg")}`,
              variant: "success",
            },
          });
          this.getProducts({
            skip: 0,
            take: 10,
            searchKey: "",
            productType: "products",
          });
        });
      }
    },
    onRowClicked(item) {
      this.getApprovedProductById(item.id).then(() => {
        this.modalProduct = this.product;
        this.$bvModal.show("product-show-modal");
      });
    },
    async getMobileView(item) {
      await this.getApprovedProductById(item.id);
      console.log("prod mobile view", this.product);
      this.mobileViewProduct = this.product;
      this.$bvModal.show("product-mobile-modal");
    },
  },
};
</script>

<style>
.filters .form-group {
  max-width: 300px;
}
.date-control {
  width: 100%;
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  display: block;
}
</style>
