<template>
  <div class="filters d-flex justify-content-between align-items-end">
    <b-form-group
      :label="$t('global.perPage')"
      class="form-group mr-1"
      label-for="per-page-select"
    >
      <b-form-select
        id="per-page-select"
        v-model="perPage"
        :options="perPageOptions"
      ></b-form-select>
    </b-form-group>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
    />
  </div>
</template>

<script>
import { BPagination, BFormGroup, BFormSelect } from "bootstrap-vue";

export default {
  components: {
    BFormSelect,
    BPagination,
    BFormGroup,
  },
  props: {
    totalRows: {
      type: Number,
      default: 1,
    },
    take: {
      type: Number,
      default: 20,
    },
    fetchData: {
      type: Function,
      default: () => {
        return true;
      },
    },
    skip: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    perPageOptions() {
      return [10, 20, 50, 100];
    },
    perPage: {
      get() {
        return this.take;
      },
      set(val) {
        this.$emit("update:take", val);
      },
    },
    skipValue: {
      get() {
        return this.skip;
      },
      set(val) {
        this.$emit("update:skip", val);
      },
    },
  },
  watch: {
    currentPage(newVal) {
      this.skipValue = (newVal - 1) * this.take;
      this.fetchData();
    },
  },
};
</script>
