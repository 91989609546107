<template>
  <div v-if="!data.item.hiddenUntilAt || (new Date() > new Date(data.item.hiddenUntilAt))" class="d-flex">
    <flat-pickr
      v-model="hideDate"
      class="date-control"
      :config="{
        enableTime: true,
        dateFormat: 'Y-m-d H:i:ss',
      }"
    />
    <b-button
      :disabled="!hideDate"
      variant="flat-success"
      class="btn-icon ml-1"
      @click="hideProductButton(data.item, hideDate)"
    >
      <feather-icon size="16" icon="SaveIcon" />
    </b-button>
  </div>
  <div v-else class="d-flex align-items-center">
    <p class="mb-0 mr-1">
      {{ data.item.hiddenUntilAt }}
    </p>
    <b-button variant="success" size="sm" class="btn-icon" @click="handleUnhideButton(data.item)"> Unhide </b-button>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "HiddenUntilAt",
  components: {
    BButton,
    flatPickr,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    hideProductButton: {
      type: Function,
      required: true,
    },
    unhideProductButton: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      hideDate: null,
    };
  },
  methods: {
    async handleUnhideButton(item) {
      try {
        await this.unhideProductButton(item, this.hideDate);
        this.hideDate = null;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.date-control {
  width: 100%;
  min-width: 175px;
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  display: block;
}
</style>
